import productDb from '../productDb';
import Products from "./Products";
import App from '../App';
import CategoryHeader from "./CategoryHeader";

import {CATEGORIES} from "../utils";
import {GENDERS} from "../utils";

import {useState} from "react";


export default function CategoryPage(props) {
    const { title, subtitle, primaryFilters } = props;
    // const navigate = useNavigate();
    // const {cart, updateState} = useContext(UserContext);
    // const [ paymentDetails, setPaymentDetailes ] = useState({
    //     isPaymentDetailsStep: false
    // })

    const [pickedFilters, setPickedFilters] = useState(primaryFilters);


    return (
        <App>
            <CategoryHeader
                title={title}
                subtitle={subtitle}
                pickedFilters={ pickedFilters }
                setPickedFilters={ setPickedFilters }
                products={ productDb }
                simpleFunction={() => { console.log('simple function') }}
            >
                <Products
                    products={
                        productDb
                            .filter(product => {
                                    if (!pickedFilters.length) {
                                        return true;
                                    }

                                    for (let i = 0; i < pickedFilters.length; i++) {
                                         if (pickedFilters[i].type == 'category' && !product.categories.includes(pickedFilters[i].value)) {
                                        return false
                                        }
                                    }
                                    return true;
                                }
                            )
                    }
                />
            </CategoryHeader>
        </App>
    );
}
